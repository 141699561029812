import React from 'react'
import styles from '../components/authorDetails.module.scss'
import Img from './responsiveCloudinary'
import styled from 'styled-components'

class AuthorDetails extends React.Component {
  share(url, height, e) {
    e.preventDefault()

    // Set values for window
    const intWidth = '560'
    const intHeight = height
    const left = window.screen.width / 2 - intWidth / 2
    const top = window.screen.height / 2 - intHeight / 2

    // Set title and open popup with focus on it
    const strTitle = 'Social Share'
    const strParam = `width=${intWidth},height=${intHeight},top=${top},left=${left},resizable=no`

    window.open(url, strTitle, strParam).focus()
  }

  render() {
    // &title=Avenue%Blog%Post&summary=${this.props.blogPost.frontmatter.title}

    return (
      <div className={styles.author__row}>
        <div className={styles.author__container}>
          <div>
            <div className={styles.author__line}></div>
          </div>
          <div className={styles.author__inner__container}>
            <div className={styles.author}>
              <Img user="avenue" publicId={this.props.author.img} alt="" />
            </div>
            <div>
              <div className={styles.author__title}>
                {this.props.author.title}
              </div>
              <div className={styles.author__text}>
                {this.props.author.text}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AuthorDetails
