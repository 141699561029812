import React from 'react'
import OnVisible from 'react-on-visible'

import Img from './responsiveCloudinary'

import styles from './image.module.scss'

class Template extends React.Component {
  render() {
    let inlineStyle = {
      margin: this.props.margin || null,
      padding: this.props.padding || null,
      backgroundColor: this.props.background || null,
      maxHeight: this.props.maxHeight || null,
    }

    if (this.props.paddingTop || this.props.paddingBottom) {
      inlineStyle = {
        ...inlineStyle,
        paddingTop: this.props.paddingTop || null,
        paddingBottom: this.props.paddingBottom || null,
      }
    }

    if (this.props.marginTop || this.props.marginBottom) {
      inlineStyle = {
        ...inlineStyle,
        marginTop: this.props.marginTop || null,
        marginBottom: this.props.marginBottom || null,
      }
    }

    if (this.props.maxWidth) {
      inlineStyle = {
        ...inlineStyle,
        maxWidth: this.props.maxWidth || null,
      }
    }

    return (
      <section
        className={`${styles.block} ${styles[this.props.margin]} ${
          styles[this.props.width]
        }`}
        style={inlineStyle}
      >
        <Img
          gif={this.props.gif}
          user="avenue"
          className={`${styles[this.props.variation]}`}
          publicId={this.props.image}
          quality={this.props.quality}
          xlarge={this.props.variation === 'bleedBoth' ? '1800px' : '1600px'}
          alt=""
        />
      </section>
    )
  }
}

export default Template
