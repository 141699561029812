import React from 'react'
import styles from './ImgText.module.scss'
import Img from './responsiveCloudinary'

export default ({
  img,
  gif,
  title,
  text,
  text2,
  link,
  imagePosition,
  linkText,
}) => {
  return (
    <div className={`${styles.imgText__container} ${styles[imagePosition]}`}>
      <div className={styles.img__container}>
        <Img gif={gif} user="avenue" publicId={img} alt="roundup-img" />
      </div>

      <div className={styles.text__container}>
        <div className={styles.title}>{title}</div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        {text2 && <div className={styles.text}>{text2}</div>}

        {linkText && (
          <a
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href={link}
          >
            {linkText}
          </a>
        )}
      </div>
    </div>
  )
}
