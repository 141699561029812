import React from "react"
import ImgText from "../components/ImgText"
import FullWidthImage from "../components/fullWidthImage"
import HtmlText from "../components/htmlText"
import Video from "../components/video"
import CSImageBlock from "../components/caseStudies/CSImageBlock"
import TestimonialText from "../components/testimonialText"
import GetInTouch from "../components/getInTouch"
import Author from "../components/author"
import Image from "../components/image"

import { graphql } from "gatsby"
import GlobalComponents from "../components/globalcomponents"
import SocialMeta from "../components/socialMeta"
import BlogPostHeader from "../components/blogPostHeader"
import DoubleImg from "../components/doubleImg"

const templates = {
  HtmlText,
  Video,
  ImgText,
  Image,
  FullWidthImage,
  CSImageBlock,
  Author,
  DoubleImg,
  TestimonialText,
}

const keyBy = (arr) => {
  let attrs = {}
  arr.forEach(function (pair) {
    attrs[pair.key] = pair.value
  })
  return attrs
}

const renderRowTemplate = ({ id, component, props }, blogPost, slug) => {
  const Template = templates[component]
  const attrs = keyBy(props, "key")

  return (
    <Template
      key={id}
      {...attrs}
      slug={slug}
      html={blogPost.html}
      blogPost={blogPost}
    />
  )
}

class BlogPostTemplate extends React.Component {
  render() {
    const blogPost = this.props.data.cs
    const { blocks } = blogPost.frontmatter
    const slug = this.props.location.pathname
    // const { related } = this.props.data

    return (
      <GlobalComponents>
        <SocialMeta
          twitterImage={`http://res.cloudinary.com/avenue/image/upload/${blogPost.frontmatter.headerImage}`}
          ogImage={`http://res.cloudinary.com/avenue/image/upload/${blogPost.frontmatter.headerImage}`}
          ogTitle={`${blogPost.frontmatter.title}`}
          ogURL={this.props.location.pathname}
        />

        <BlogPostHeader blogPost={blogPost} />

        <div>
          {blocks &&
            blocks.map((block, i) => renderRowTemplate(block, blogPost, slug))}
        </div>

        <GetInTouch
          left="Think we might be a good fit for your project?"
          right="Let's have a chat"
          action="/contact"
          cols={[9, 1, 2]}
        />
      </GlobalComponents>
    )
  }
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostQuery($slug: String!, $related: String!) {
    cs: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        headerImage
        headerTitle
        headerSize
        description
        related
        date(formatString: "DD MMM YY")
        tag
        template
        blocks {
          id
          component
          props {
            key
            value
          }
        }
      }
    }
    related: markdownRemark(fields: { slug: { eq: $related } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        headerImage
      }
    }
  }
`
