import Img from './responsiveCloudinary'
import InternalHeader from './internalHeader'
import styles from './blogPostHeader.module.scss'
import React from 'react'

const BlogPostHeader = ({ blogPost }) => {
  return (
    <div
      className={`${styles.header} ${styles[blogPost.frontmatter.template]}`}
    >
      <InternalHeader
        title={`${blogPost.frontmatter.title} Blog Post`}
        headerTitle="The Latest"
        description={blogPost.frontmatter.description}
        flag="OUR BLOG"
        img="header_big_full_cziaau"
        template="background"
        size="blogpost"
      />

      <div className={`${styles.blogPostHeaderContainer}`}>
        {blogPost.frontmatter.template === 'standard' && (
          <div className={styles.imgWrapper}>
            <Img
              user="avenue"
              publicId={blogPost.frontmatter.headerImage}
              alt=""
            />
            <div className={styles.overlay} />
          </div>
        )}

        <div className={styles.headerTextContainer}>
          <span>
            <p>{blogPost.frontmatter.date}</p>
            <p>|</p>
            <p>{blogPost.frontmatter.tag}</p>
          </span>

          <p className={blogPost.frontmatter.headerSize}>
            {blogPost.frontmatter.headerTitle}
          </p>
          <p className={styles.roundUpText}>
            'Roundup' is the collection of key stuff we've either been reading,
            discussing or working on during the month at Avenue.
          </p>
        </div>
      </div>
    </div>
  )
}

export default BlogPostHeader
