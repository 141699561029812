import React from 'react'
import AuthorDetails from './authorDetails'

export default ({ slug, blogPost, template }) => {
  const author = template

  const brenton = {
    title: 'Brenton Cannizarro',
    img: 'brenton_f2zfkj',
    text: 'Believing hair should be long and celebrated, Brenton was ironically bald at 28. A lover of the world game, he will never walk alone, and also has a deep passion for hard rock music.',
  }

  const jon = {
    title: 'Jon Low',
    img: 'jon-profile_g0ujlr',
    text: 'Without his glasses, he’s a risk to himself and others. You’ll find Jon cursing on the golf course, or living with the injustice of being an Adelaide Crows supporter.',
  }
  const flavio = {
    title: 'Flavio Argemi',
    img: 'brenton_f2zfkj',
    text: 'Despite there being 21 letters in his full name (true story), people just call him ‘Fabio’. Flav loves a good video game and playing one of the guitars from his collection.',
  }

  if (author === 'Brenton') {
    return <AuthorDetails author={brenton} slug={slug} blogPost={blogPost} />
  } else if (author === 'Jon') {
    return <AuthorDetails author={jon} slug={slug} blogPost={blogPost} />
  } else if (author === 'Flavio') {
    return (
      <div>
        <AuthorDetails author={flavio} slug={slug} blogPost={blogPost} />
      </div>
    )
  }
}
